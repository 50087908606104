export const userInfoQuery = () =>
    `query {
      user {
        name
      },
      serverInfo {
        name
        company
      }
    }`

export const projectSearchQuery = search =>
    `query {
      activeUser {
        projects(filter: { search: "${search}" }) {
          totalCount
          cursor
          items {
            id
            name
            description
            updatedAt
            createdAt
          }
        }
      }
    }`

export const projectModelsQuery = (projectId, itemsPerPage, cursor) =>
    `query {
        project(id: "${projectId}") {
            versions(limit: ${itemsPerPage}, cursor: ${cursor ? '"' + cursor + '"' : null}) {
                totalCount
                cursor
                items {
                    id
                    sourceApplication
                    createdAt
                    totalChildrenCount
                    model {
                      id
                      name
                    }
                    referencedObject
                }
            }
        }
    }`

export const projectModelQuery = (projectId, versionId) => `
    query {
        project(id: "${projectId}") {
            id
            name
            version(id: "${versionId}") {
                id
                sourceApplication
                createdAt
                totalChildrenCount
                model {
                  id
                  name
                }
                referencedObject
            }
        }
    }`

export const modelObjectsQuery = (projectId, versionId, itemsPerPage, cursor) => `
    query {
        project(id: "${projectId}") {
            object(id: "${versionId}") {
                id
                totalChildrenCount
                children(
                    limit: ${itemsPerPage}, 
                    cursor: ${cursor ? '"' + cursor + '"' : null}, 
                    select: [
                        "elementId",
                        "name",
                        "speckle_type", 
                        "type", 
                        "family", 
                        "category", 
                        "units"
                        "materialQuantities.id", 
                        "materialQuantities.area", 
                        "materialQuantities.units", 
                        "materialQuantities.length", 
                        "materialQuantities.volume", 
                        "materialQuantities.speckle_type"
                      ],
                        
                ) {
                    totalCount
                    objects{
                        id
                        data
                    }
                }
            }
        }
    }`

// query: [{field: "materialQuantities.id", value: null, operator: "!="}]