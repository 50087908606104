<template lang="html">
  <v-app>
    <v-app-bar v-if="isAuthenticated" app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
            :src="require(`@/assets/img/logo/sj-speckle-logo-long.png`)"
            alt="Speckle Logo"
            class="shrink mr-2"
            contain
            height="50"
            transition="scale-transition"
            width="150"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
          v-if="!isAuthenticated"
          outlined
          @click="$store.dispatch('redirectToAuth')"
      >
        <span>Login with Speckle</span>
      </v-btn>
      <v-btn v-else outlined @click="$store.dispatch('logout')">
        Log out
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  }
}
</script>

<style lang="scss">

</style>