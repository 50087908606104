<template>
  <v-autocomplete
      v-model="selectedSearchResult"
      :items="projects.items"
      :search-input.sync="search"
      no-filter
      counter="2"
      rounded
      filled
      dense
      flat
      hide-no-data
      hide-details
      placeholder="Project Search"
      item-text="name"
      item-value="id"
      return-object
      clearable
      append-icon=""
      @update:search-input="debounceInput"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <v-row class="pa-0 ma-0">
            {{ item.name }}
            <v-spacer></v-spacer>
            <span class="streamid">{{ item.id }}</span>
          </v-row>
        </v-list-item-title>
        <v-list-item-subtitle class="caption">
          Updated
          <time-ago :datetime="item.updatedAt"></time-ago>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from "lodash"
import {searchProjects} from "@/speckleUtils"
import { TimeAgo } from 'vue2-timeago'

export default {
  name: "ProjectSearch",
  data: () => ({
    search: "",
    projects: { items: [] },
    selectedSearchResult: null
  }),
  components: { TimeAgo },
  watch: {
    selectedSearchResult(val) {
      this.search = ""
      this.projects.items = []
      if (val) this.$emit("selected", val)
    }
  },
  methods: {
    async fetchSearchResults(e) {
      if (!e || e?.length < 3) return
      console.log("Searching for:", e)
      const json = await searchProjects(e);
      console.log("Search results:", json)
      this.projects = json.data.activeUser.projects
    },
    debounceInput: debounce(function(e) {
      this.fetchSearchResults(e)
    }, 300)
  }
}
</script>

<style scoped></style>
