<template>
  <v-container
      class="home d-flex flex-column justify-center align-center text-center colored-bg"
      fill-height
      fluid
  >
    <v-card
        class="pa-6 rounded-lg elevation-10"
        elevation="10"
        style="max-width: 500px; background: #f1f0ec;"
    >
      <!-- Add the image (logo) here -->
      <v-img
          alt="Speckle SJ Logo"
          class="mb-4 mx-auto d-block"
          contain
          max-height="150"
          max-width="400"
          src="@/assets/img/logo/sj-speckle-logo.png"
          style="display: block;"
      />

      <!-- Ensuring text-center for the card title -->
      <v-card-subtitle class="text-h5 text-center font-weight-bold justify-center">
        Log ind for at se dine <br> Speckle projekter
      </v-card-subtitle>
      <v-btn block class="mt-4 primary" large @click="$store.dispatch('redirectToAuth')">
        Log In
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "WelcomeView",
};
</script>
