<template lang="html">
  <WelcomeView v-if="!$store.getters.isAuthenticated"/>
  <v-container v-else class="home pa-6 ">
    <project-search
        @selected="$store.dispatch('handleStreamSelection', $event)"
    />
    <h2 class="pt-6 primary--text">
      <span v-if="selectedProject">
        {{ selectedProject.name }} — {{ selectedProject.id }}
        <v-btn
            :href="serverUrl + '/streams/' + selectedProject.id"
            class="ml-3"
            outlined
            small
            target="_blank"
            text
        >
          View in server
        </v-btn>
        <v-btn
            class="ml-3"
            color="error"
            outlined
            small
            text
            @click="$store.dispatch('clearStreamSelection')"
        >
          Clear selection
        </v-btn>
      </span>
      <span v-else>
        <em>No project selected. Find one using the search bar 👆🏼</em>
      </span>
    </h2>

    <div v-if="selectedProject" class="pt-6">
      <h3 class="pa-2 primary--text">Versions:</h3>
      <v-data-table
          :disable-pagination="loading"
          :headers="filteredHeadersWithAction"
          :items="models ? models.items : []"
          :loading="loading"
          :options.sync="options"
          :server-items-length="models ? models.totalCount : null"
          class="elevation-1"
          disable-filtering
          disable-sort
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
              outlined
              small
              @click="goToStream(selectedProject.id, item.id)"
          >
            View Details
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-footer v-if="isAuthenticated" app padless>
      <v-row align="center" class="text-center" justify="center">
        <v-col cols="12">
          <div>
            Welcome
            <b>{{ $store.state.user.name }}</b>
            ! You are connected to
            <b>
              {{ $store.state.serverInfo.company }}'s
              <em>{{ $store.state.serverInfo.name }}</em>
            </b>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import WelcomeView from "@/components/WelcomeView"
import ProjectSearch from "@/components/ProjectSearch.vue";

export default {
  name: "HomeView",
  components: {WelcomeView, ProjectSearch},
  data: () => {
    return {
      loading: false,
      options: {
        itemsPerPage: 10
      },
      serverUrl: process.env.VUE_APP_SERVER_URL,
      selectedKeys: ["id", "model.name", "sourceApplication", "createdAt"]
    }
  },
  mounted() {
    var storedOpts = this.$store.state.tableOptions
    if (storedOpts) this.options = storedOpts
  },
  methods: {
    goToStream(projectId, versionId) {
      this.$router.push(`/project/${projectId}/version/${versionId}`)
    },
  },
  computed: {
    selectedProject: function () {
      return this.$store.state.currentStream
    },
    previousCursors: function () {
      return this.$store.state.previousCursors || [null]
    },
    models: function () {
      return this.$store.state.latestCommits
    },
    filteredHeaders: function () {
      return this.selectedKeys.map(key => {
        return {text: key, value: key}
      })
    },
    filteredHeadersWithAction() {
      return [
        ...this.filteredHeaders,
        {text: "Action", value: "action", sortable: false},
      ];
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  watch: {
    options: {
      async handler(val, oldval) {
        this.$store.commit("setTableOptions", val)
        if (oldval.page && val.page !== oldval.page) {
          if (val.page > oldval.page) {
            this.loading = true
            var cursor = this.$store.state.latestCommits.cursor
            await this.$store.dispatch("getModels", cursor)
            this.$store.commit("addCursorToPreviousList", cursor)
            this.loading = false
          } else {
            console.log("page down")
            this.loading = true
            await this.$store.dispatch(
                "getModels",
                this.previousCursors[val.page - 1]
            )
            this.loading = false
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#viewer {
  min-height: 500px;
}

.v-data-footer__select {
  display: none !important;
}

</style>
