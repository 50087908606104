import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@/styles/vars.scss'; // Import SCSS variables file for global access

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#31521b',     // Custom Primary color (manually set from $color-primary)
                secondary: '#afb791',   // Custom Secondary color (manually set from $color-secondary)
                accent: '#f57100',      // Custom Accent color (manually set from $color-accent)
                background: '#e8e7e1',   // Background color (manually set from $color-white)
                error: '#373632',       // Error color (manually set from $color-dark)
                info: '#7a756c',        // Info color (manually set from $color-medium)
                success: '#afb791',     // Success color (manually set from $color-secondary)
                warning: '#f57100',     // Warning color (manually set from $color-accent)
            },
            dark: {
                primary: '#31521b',     // Dark Mode Primary color (manually set from $color-primary)
                secondary: '#afb791',   // Dark Mode Secondary color (manually set from $color-secondary)
                error: '#373632',       // Dark mode error (manually set from $color-dark)
                info: '#7a756c',        // Dark mode info (manually set from $color-medium)
                success: '#afb791',     // Dark mode success (manually set from $color-secondary)
                warning: '#f57100',     // Dark mode warning (manually set from $color-accent)
            }
        }
    },
    customVariables: '@/styles/vars.scss', // Load the custom SCSS variables globally
});
